import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { invalid } from '@angular/compiler/src/render3/view/util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isload:boolean=false;
  data :any={}
  constructor(public http: HttpClient,public appservice: AppService,private toastr: ToastrService, public router: Router) {

    if (this.getData()) {

      this.isload=true;
      this.data=JSON.parse(localStorage.getItem('User_Data'));
      appservice.Current_User=this.data;
      appservice.Company="_"+this.data.UM_Company;
      this.get_Token();
     
    }

    

  }
  get_Password()
  {
    return this.router.navigate(['/forgot-new-password']);
   
  }
  get_Token() {

         this.appservice.headers = new Headers({'Content-Type': 'application/x-www-form-urlencoded'});
          this.isload=true;
          this.http.post(this.appservice.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.appservice.headers })
            .subscribe(
              (val) => {
                this.appservice.tocken=val['access_token'];
                this.get_Field_Setting();
              },
              response => {
                this.toastr.error('Error ', response, {
                  timeOut: 3000
                });
                return "Error Contact Admin";
              });
        
              //return "Problem"
      }

  
  Prow=[];
  page="";

  get_registed() {
    this.router.navigate(['visitor/register-login']);
  }

  get_Field_Setting() {
    this.appservice.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {

      if(res=="No_Licence")
      {
        this.router.navigate(['/invalid-licence']);
      }
      else
      {
          this.appservice.Field_Setting = JSON.parse(res).record;
          this.get_Setting_Setting();    
      }
    });


  }


  get_variable_Value(data)
  {
    
    
 if (data == "Stockbase_Sales") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Stockbase_Sales=true;
          }
          
        }

      } catch { }

    }
    else if (data == "Check_Stock") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Check_Stock=true;
          }
          
        }

      } catch { }

    }
    
    else if (data == "Logo_Name") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Logo_Name = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
   if (data == "Sales_Entry_Page") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Sales_Entry_Page = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Balance_SMS") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Balance_SMS = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Feed_Back_Link") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Feed_Back_Link = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Due_Days") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Due_Days = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    
    else if (data == "Link") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Link = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Reports") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Reports = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
   

  }
  
  get_Setting_Setting() {
    this.appservice.getc("Api/Common/Get_Setting_Master").subscribe((res: any) => {
      this.appservice.SM_Row = JSON.parse(res).record;

      try{
    for(var i=0;i<this.appservice.SM_Row.length;i++)
    {
      this.get_variable_Value(this.appservice.SM_Row[i]["S_Variable"]);
    }
  }catch{}

      this.get_Item_Master();    
    });

  }



  get_Item_Master() {

    this.appservice.loading = true;
    this.appservice.isload=true;
    this.appservice.Item_Master_Rows=[];
    this.appservice.get("Api/Master/get_Item_Master?Order_by="+this.appservice.Item_Orderby_Name).subscribe((res: any) => {
      this.appservice.Item_Master_Rows = JSON.parse(res).record;
      this.appservice.Perment_Item_Master_Row = JSON.parse(res).record;
      this.appservice.Reset_Item_Master_Row = JSON.parse(res).record;
      this.appservice.loading = false;
      this.appservice.isload=false;
      this.get_Reference();
      try{
       this.appservice.Item_Category = [...new Set(this.appservice.Item_Master_Rows.map(item => item.Category))];
      }catch{}
    });
  }

  get_Reference() {


    this.appservice.getc("Api/Master/get_reference").subscribe((res: any) => {
     this.appservice.Reference_Rows = JSON.parse(res).record;
     this.appservice.Reference_Rows.sort((a, b) => a["label"].localeCompare(b["label"]))
 
     this.appservice.Sales_Person=this.data.UM_User_Name;
     this.appservice.CREATED_BY=this.data.UM_User_Name;
     this.appservice.Rights_ID=this.data.UM_Rights;
     this.appservice.Emp_ID=this.data.Emp_ID;
   
     this.appservice.Emp_Contact_No=this.data.Contact_No;
     

     this.appservice.Emp_Name=this.data.UM_Full_Name;
     
     this.appservice.get_Field_Setting();
     this.appservice.get_Role_Rights(this.data.UM_Rights);
    this.appservice.get_Employee_Details()
 

     try{
     this.Prow = this.appservice.Reference_Rows.filter(e => e.Ref_ID=="User_Role");
     this.appservice.Exp_Category=this.appservice.Reference_Rows.filter(e => e.Ref_ID=="Exp_Category");
     this.appservice.Rights_Name=this.Prow.filter(e => e.RGV_iID.toLowerCase()==this.data.UM_Rights)[0].RGV_vDesciption;
     this.appservice.U_Rights=this.appservice.Rights_Name.toLowerCase();

     this.appservice.Header_Disp = this.data.CM_Name;

    if(!(this.appservice.Rights_Name.toLowerCase()=="admin"))
    {
      this.appservice.Search_User=this.data.UM_User_Name;
    }
    

     }catch{}
     

    
     
try
{

   this.page=this.Prow.filter(e => e.RGV_iID.toLowerCase()==this.data.UM_Rights)[0].RGV_vCode;
   this.appservice.Lab_Home_Page=this.page;
   this.router.navigate([this.page]);
}catch{
  this.router.navigate([this.page]);
}
    });
  }

  get_Area(Rights,user) {

    this.appservice.get("Api/Setting/Get_User_Area?Rights="+Rights+"&User="+user).subscribe((res: any) => {
    this.appservice.Area_Row = JSON.parse(res).record;
    this.appservice.S_Area="All";
    });


    if(this.appservice.Rights_Name.toLowerCase()=="admin")
     {
      this.appservice.Search_User="All";

     
     }
     else
     {
      this.appservice.Search_User="All";
      //this.appservice.Search_User=this.data.UM_User_Name;
     }
  }

  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }
  public btndisable:boolean=false;
  public addD: any = {};
  rows = [];

  login(f) {

    this.btndisable=true;
    this.appservice.get("Api/Common/get_Lodge_Login?Username=" + f.form.value.Username + "&Password=" + f.form.value.Password).subscribe((res: any) => {

      
      this.btndisable=false;

      if (res!="") {
        this.error_="";
        this.rows = JSON.parse(res).record;
        /*this.userPostData.email = this.rows[0]["Email_ID"];
          this.userPostData.name = this.rows[0]["User_Name"];
          this.userPostData.provider = this.rows[0]["Provider"];
          this.userPostData.provider_id = this.rows[0]["Tocken"];
          this.userPostData.provider_pic = this.rows[0]["Provider"];
          this.userPostData.token = this.rows[0]["Tocken"];
          this.userPostData.company = this.rows[0]["Company_ID"];
          this.userPostData.Rights = this.rows[0]["Rights"];
          localStorage.setItem('Rights',  this.rows[0]["Rights"]);*/
        this.appservice.Company="_"+this.rows[0]["UM_Company"];
        localStorage.setItem('User_Data', JSON.stringify(this.rows[0]));
        localStorage.setItem('Area','All');
        this.appservice.Customer_Area="All"
        window.location.href ="\\";
        
        
      }
      else
      {

        this.error_="Invalid User Name and Password";
        this.toastr.error("Invalid User Name and Password", "Error", { timeOut: 3000 });
        
      }

    });
  }
  error_="";

  ngOnInit() {
  }

}
