
<div class=" col-md-4"></div>
<div class=" col-md-4">
    <div class="new-product-box">
        <div class="icon"><i class="bx bx-shopping-bag"></i></div> 
         <span class="sub-text d-block font-weight-bold">{{appservice.Emp_Contact_No}}</span>

         <input class="form-control" type="text" [(ngModel)]="appservice.Emp_Contact_No"
         readonly name="Sub_Phone_No" #Emp_Contact_No="ngModel">
         <br>
         <button class="btn btn-info subscribe">Subscribe</button>
    </div>
   
</div>